import React from 'react'
import { Container, Layout, SEO } from 'components/common'
import { Footer } from 'components/theme'
import Link from 'gatsby-link'
import { usePartnerData } from 'hooks/UsePartnerData'

const Index = () => {
  const { legalName, acronym } = usePartnerData()
  return (
    <Layout>
      <SEO title={legalName} />
      <header className="site-header">
        <div className="school-name">
          <Container>
            <h1>{legalName}</h1>
          </Container>
        </div>
        <div className="school-logo">
          <Container>
            <div className="logo">{acronym}</div>
          </Container>
        </div>
      </header>
      <Link to="general">Start here</Link>
      <main role="main" />
      <Footer />
    </Layout>
  )
}
export default Index
